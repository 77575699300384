// import CustomAccordion from "./accordian"
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import TrackVisibility from "react-on-screen"
import Fade from "react-reveal/Fade"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/affiliate.scss"
import "../../styles/pricing-new.scss"
import ImageContainer from "../features/image-container"
import NewAccordian from "./Accordian-new/custom-accordian"

const AccordionV2 = props => {
  const { faqComponent } = props
  const imageBlocks = faqComponent.faqs[0].imageBlocks

  const [activeAccordion, setActiveAccordion] = useState(0)

  const bg = ["#FFA144", "#6C61B9", "#47B27E", "#DC4A57"]
  const sbg = ["#ffb46d", "#8980c7", "#6cc198", "#e36e79"]

  const callbackFun = data => {
    const accprevIndex = parseInt(sessionStorage.getItem("accprevindex"))
    data != null ? setActiveAccordion(data) : setActiveAccordion(accprevIndex)
    const getac = document.querySelectorAll(".wrap-qn")
    getac[accprevIndex].style.cssText = "background: rgba(0, 0, 0, 0.2)"
    getac[
      data != null ? data : accprevIndex
    ].style.cssText = `background: #170426`
    sessionStorage.setItem("accprevindex", data != null ? data : accprevIndex)
    setTimeout(() => {
      const getac = document.querySelectorAll(".wrap-qn")
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        data != null
      ) {
        window.scrollTo({
          top: getac[data].offsetTop - 120,
          behavior: "smooth",
        })
      }
    }, 500)
  }
  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem("accprevindex")
    }
    const checkPrev = () => {
      var accprevIndex = sessionStorage.getItem("accprevindex")
      if (accprevIndex === null) {
        sessionStorage.setItem("accprevindex", 0)
      }
    }
    checkPrev()
  }, [])

  return (
    <>
      <section className="accordionV2 d-flex justify-content-center">
        <div
          className="Layout-max outer d-flex justify-content-center"
          style={{ background: bg[activeAccordion] }}
          id="bgchange"
        >
          <div className="left">
            <div className="altv3 p16">
              <Fade bottom duration="1500">
                <h1 className="heading">{faqComponent.description}</h1>
              </Fade>
            </div>
            <TrackVisibility
              once
              children
              partialVisibility={true}
              offset={-200}
            >
              <NewAccordian
                faqComponent={faqComponent}
                callback={callbackFun}
              />
            </TrackVisibility>
          </div>
          <div className="d-dsk right">
            <div
              className="position-relative d-flex align-items-center justify-content-end h100"
              style={{ marginLeft: "50px" }}
            >
              {faqComponent.faqs[0].images.map((imgUrl, idx) => (
                <div
                  className={`${
                    activeAccordion === idx
                      ? "fillanimae"
                      : "fade-out-effect dspnone"
                  } accordion-img-wrapper position-absolute`}
                  css={css`
                  &:before{
                    background: ${sbg[activeAccordion]};
                    height:${
                      imageBlocks[activeAccordion].blockImageTitle.length > 1
                        ? "95%"
                        : "100%"
                    };
                  `}
                >
                  <ImageContainer
                    blockImageTitle={imageBlocks[idx].blockImageTitle}
                    blockImage={imageBlocks[idx].blockImage}
                    blockIsIllustration={imageBlocks[idx].blockIsIllustration}
                    blockIsVideo={imageBlocks[idx].blockIsVideo}
                    customBg={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AccordionV2
