import React from "react"

function WistiaVideoPopupSpan(props) {
  return (
    <>
      <span
        className="wistia_embed wistia_async_53ka8ekgsc popover=true popoverAnimateThumbnail=true"
        id={props.wistiaVideoId}
        style={{
          display: "inline-block",
          visibility: "hidden",
          position: "relative",
          height: "10px",
          width: "10px",
        }}
      >
        &nbsp;
      </span>
    </>
  )
}

export default WistiaVideoPopupSpan
