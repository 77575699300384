import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect } from "react"
import {
  AccordionHeader,
  AccordionNode,
  AccordionPanel,
  AccordionWithHeader,
} from "react-accordion-with-header"
import "../../../styles/component/faq.scss"
import ImageContainer from "../../features/image-container"
import SVGIcon from "../SVGIcon"
import "./custom-accordian.scss"

const NewAccordian = props => {
  const {
    question,
    answerrt,
    images,
    linkText,
    slug,
    imageBlocks,
  } = props.faqComponent.faqs[0]

  useEffect(() => {
    const getImg = document.querySelectorAll(".accordion-img-wrapper")
    if (getImg != null)
      getImg[0].style.cssText = `transform: translateY(-80px);
    opacity: 0`
    const getac = document.querySelectorAll(".wrap-qn")

    if (props.isVisible)
      for (let i = 0; i < getac.length; i++) {
        setTimeout(() => {
          const getclick = document.querySelectorAll(".accordion-new")
          const getImg = document.querySelectorAll(".accordion-img-wrapper")
          getac[i].classList.add("accordAnimate")
          i == getac.length - 1 &&
            setTimeout(() => {
              getclick[0].click()
              getImg[0].style.cssText = `transform: none;
              opacity: 1`
            }, i * 100)
        }, i * 100)
      }
  }, [props.isVisible])
  return (
    <AccordionWithHeader
      // firstOpen={true}
      onChange={(panels, state) => console.log(panels, state)}
      actionCallback={(panels, state) => {
        props.callback(state.active[0])
      }}
      className="myaccord"
      style={{ boxShadow: "none" }}
    >
      {question.map((q, i) => {
        return (
          <AccordionNode key={i} className="wrap-qn">
            <AccordionHeader
              className="accordion-new"
              style={{
                padding: "24px 24px 28px 24px",
              }}
            >
              {q}
              <div className="">
                <SVGIcon
                  name="accordion/down-arrow"
                  className="arrow-down position-absolute"
                />
              </div>
            </AccordionHeader>
            <AccordionPanel className="card-body-new" speed={200}>
              <div className="content">
                {/* <div className="w-100 d-mob mobile-image-wrap">
                  <img
                    src={images[i].url}
                    alt="accordionImg"
                    className="w-100"
                  />
                  
                </div> */}
                <div className="d-mob accord-mob-wrap mb24">
                  <ImageContainer
                    blockImageTitle={imageBlocks[i].blockImageTitle}
                    blockImage={imageBlocks[i].blockImage}
                    blockIsIllustration={imageBlocks[i].blockIsIllustration}
                    blockIsVideo={imageBlocks[i].blockIsVideo}
                    customBg={true}
                  />
                </div>
                {parse(answerrt[i].html)}
                <Link to={`${slug[i]}`} className="hyperlink-arrow">
                  {linkText[i]}
                </Link>
              </div>
            </AccordionPanel>
          </AccordionNode>
        )
      })}
    </AccordionWithHeader>
  )
}
export default NewAccordian
