import React from "react"

import "../../styles/component/video-player.scss"

import WistiaVideoOnclickEmbed from './wistia-video-onclick-embed'
import WistiaVideoPopupSpan from './wistia-video-popup-span'

let VideoPlayer = props => {
  const showPopupVideo = () => {
    document.querySelector("#wistiaEmbed > div > div").click()
  }

  return (
    <>
      <div
        className="video-player"
        onClick={() => showPopupVideo()}
        onKeyDown={() => showPopupVideo()}
        role="button"
        tabIndex={0}
      >
        <WistiaVideoOnclickEmbed videoScreenshot={props?.videoScreenshot?.url} />
      </div>
      <WistiaVideoPopupSpan wistiaVideoId={props.wistiaVideoId} />
    </>
  )
}

export default VideoPlayer
