import { easeQuadInOut } from "d3-ease"
import React, { useState } from "react"
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import Fade from "react-reveal/Fade"
import AnimateProgress from "../../components/utility/animate_progress"
import useInterval from "../../components/utility/useInterval"

function Slider(props) {
  const [activeIndex, setActiveIndex] = useState(0)

  const indexLength = props.title.length

  const [delay, setDelay] = useState(10000)

  useInterval(() => {
    if (activeIndex < indexLength - 1) {
      setActiveIndex(activeIndex + 1)
    } else {
      setActiveIndex(0)
    }
  }, delay)

  const resetActiveIndex = index => {
    if (delay < 10000) {
      setDelay(10000)
    } else {
      setDelay(9999)
    }

    setActiveIndex(index)
  }

  return (
    <>
      <Fade>
        {props.title.map((title, index) => {
          return (
            <>
              {typeof props.isVideo === "undefined" ? (
                <img
                  src={props.image[index].url}
                  className={`fe-slider-img ${props.isIllustration[index] ===
                    "No" && "fe-bx-img"}`}
                  style={{
                    display: index === activeIndex ? "block" : "none",
                  }}
                  alt="img"
                />
              ) : (
                <>
                  {props.isVideo[index] === "Yes" && (
                    <video
                      height="100"
                      width="100"
                      loop="true"
                      autoplay="autoplay"
                      className="feat-video fe-bx-img"
                      muted
                      style={{
                        display: index === activeIndex ? "block" : "none",
                      }}
                    >
                      <source
                        type="video/mp4"
                        src={props.image[index].url}
                      ></source>
                    </video>
                  )}

                  {props.isVideo[index] !== "Yes" && (
                    <img
                      src={props.image[index].url}
                      className={`fe-slider-img ${props.isIllustration[
                        index
                      ] === "No" && "fe-bx-img"}`}
                      style={{
                        display: index === activeIndex ? "block" : "none",
                      }}
                      alt="img"
                    />
                  )}
                </>
              )}
            </>
          )
        })}
        <div
          className={`fe-slider-align ${
            !props.customBg
              ? null
              : props.title.length > 2
              ? "justify-content-around gap83"
              : "justify-content-start gap83"
          }`}
        >
          {props.title.map((title, index) => {
            return (
              <div
                className="fe-slider-title"
                onClick={() => resetActiveIndex(index)}
                onKeyDown={() => resetActiveIndex(index)}
                role="button"
                tabIndex={0}
              >
                <div className="fe-better-thumb">
                  <AnimateProgress
                    valueStart={0}
                    valueEnd={index === activeIndex ? 100 : 0}
                    duration={index === activeIndex ? 10 : 0.3}
                    easingFunction={easeQuadInOut}
                    repeat={false}
                  >
                    {value => {
                      return (
                        <CircularProgressbarWithChildren
                          value={value}
                          strokeWidth={16}
                          className={`circular-pb-testim-fe ${
                            index === activeIndex ? "active" : ""
                          }`}
                          styles={buildStyles({
                            pathTransition: "none",
                          })}
                        >
                          <div
                            className={`fe-timer-div ${
                              index === activeIndex ? "active" : ""
                            }`}
                          ></div>
                        </CircularProgressbarWithChildren>
                      )
                    }}
                  </AnimateProgress>

                  <p
                    style={{
                      color: props.customBg
                        ? null
                        : index === activeIndex
                        ? "#FF0055"
                        : "#170426",
                    }}
                  >
                    {" "}
                    {title}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </Fade>
    </>
  )
}

export default Slider
