import React, { forwardRef } from "react"
import Slider from "../../components/features/slider"
import SVGIcon from "../common/SVGIcon"

function ImageContainer(props, ref) {
  return (
    <>
      {props.blockImageTitle.length > 1 && (
        <Slider
          image={props.blockImage}
          title={props.blockImageTitle}
          isIllustration={props.blockIsIllustration}
          isVideo={props.blockIsVideo}
          customBg={props.customBg && props.customBg}
        />
      )}
      {props.blockImageTitle.length === 1 && (
        <>
          {props.blockIsVideo[0] === "Yes" && (
            <video
              height="100"
              width="100"
              loop="true"
              autoplay="autoplay"
              className="fe-slider-img fe-bx-img"
              muted
              ref={el => {
                ref && ref(el)
              }}
            >
              <source type="video/mp4" src={props.blockImage[0].url}></source>
            </video>
          )}

          {props.blockIsVideo[0] !== "Yes" && (
            <div className="position-relative">
              {props.decoration && (
                <>
                  <SVGIcon
                    name="block/purpleLine"
                    className="position-absolute purple-line"
                  />
                  <SVGIcon
                    name="block/redScribble"
                    className="position-absolute red-scribble"
                  />
                  <SVGIcon
                    name="block/yellowCircle"
                    className="position-absolute yellow-circle"
                  />
                  <SVGIcon
                    name="block/greenScribble"
                    className="position-absolute green-scribble"
                  />
                </>
              )}

              <img
                src={props.blockImage[0].url}
                className={`fe-slider-img ${props.blockIsIllustration[0] ===
                  "No" && "fe-bx-img"}`}
                alt="img"
              />
            </div>
          )}
        </>
      )}{" "}
    </>
  )
}

export default forwardRef(ImageContainer)
