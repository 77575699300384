import React from "react"
import { Col, Container } from "react-bootstrap"
import Tilt from "react-parallax-tilt"
import Fade from "react-reveal/Fade"

const Badges = props => {
  const { images } = props

  let mobImages = images
    .filter((item, i) => i === 0)
    .concat(images.filter((item, i) => i === 2))
    .concat(images.filter((item, i) => i === 1))
    .concat(images.filter((item, i) => i >= 3))

  return (
    <div className="badges bg-img mx-auto">
      <Container className="Layout-container">
        <div className="g2-awards">
          <Col lg={10} className="mx-auto text-center">
            <div className="d-dsk d-flex justify-content-center align-items-center gap-40">
              {images.map((img, i) => {
                return (
                  <Fade
                    bottom
                    delay={
                      ((i === 1 || i == 3) && 200) ||
                      ((i === 0 || i === 4) && 300)
                    }
                  >
                    <Tilt>
                      <div
                        style={{
                          marginTop:
                            ((i === 0 || i == 4) && "40px") ||
                            ((i === 1 || i === 3) && "16px"),
                        }}
                      >
                        <img src={img.url} alt="awd" width="145px" />
                      </div>
                    </Tilt>
                  </Fade>
                )
              })}
            </div>
            <div
              className="d-mob d-flex flex-wrap justify-content-evenly align-items-center"
              style={{ rowGap: "26px" }}
            >
              {mobImages.map((img, i) => {
                return (
                  <Fade bottom delay={i !== 1 && 200}>
                    <Tilt>
                      <img src={img.url} alt="awd" style={{ width: "100px" }} />
                    </Tilt>
                  </Fade>
                )
              })}
            </div>
          </Col>
        </div>
      </Container>
    </div>
  )
}

export default Badges
